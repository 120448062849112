.left {
  text-align: left;
}

.errorMessage {
  display: block;
  text-align: left;
  margin-bottom: 0.5em;
}
.spacer{
  height:5px;
  width:100%
}
.link{
  color: rgb(130, 130, 130);
  cursor: pointer;
  text-decoration: none; 
}

.centered{
  display: flex;
  flex-direction: column;
  align-items: center
}


.ScrollArea {
  display: flex;
  max-height: 160px;
  width: 400px;
  overflow-y: scroll;
  border: 1px solid gainsboro;
  border-radius: 5px;
  box-sizing: border-box;
}

.ScrollArea:focus-within {
  outline: 2px solid #3f51b5;
  outline-offset: 2px;
}

.ScrollContent {
  display: flex;
  padding: 16px;
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
}

textarea {
  field-sizing: content;
  width: 100%;
  resize: none;
  border: none;
  box-sizing: border-box;
  padding: 0;
  outline: none;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  vertical-align: middle;
  overflow: hidden;
  background-color: transparent;
}